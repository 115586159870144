import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import PageHeader from "../components/pageSections/pageHeader"
import ParagraphTabs from "../components/pageSections/paragraphTabs"

const AboutHindujaAges = (props) => {
const { data } = props
let pageTitle = ''
let pageInfo = {}
let components = []
let metaTag = []
if (data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
pageInfo = data.allNodeTemplatePages.edges[0]
pageTitle = pageInfo?.node?.title ? pageInfo.node.title : ''
components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
metaTag = pageInfo?.node?.metatag ? pageInfo.node.metatag : []
}

return (
<Layout>
    <Meta 
    files={
    {
    js: [],
    css: ['/assets/css/about-through-the-ages.css']
    }
    }
    tags={metaTag}
    />
    <main className="innerpage">
        <section className="section_bg pt-first-section pb-section about-ages">
            <div className="container">
                <div className="section-head">
                    <PageHeader
                        title={pageTitle}
                        headTag="h1"								
                        />
                </div>
            </div>
            <ParagraphTabs
                content={components}
                />
        </section>
    </main>
</Layout>
)
}
export const query = graphql`
query aboutThroughTheAgesPage {
allNodeTemplatePages(filter: {path: {alias: {regex: "/about-through-the-ages$/"}}}) {
edges {
node {
id
title
path {
alias
}
metatag {
    attributes {
        content
        href
        name
        property
        rel
    }
}
relationships {
components: field_component_type {
__typename
...ParagraphTitleAndDescription
...ParagraphInset
...ParagraphTabComponentAges
...ParagraphHTMLText
...ParagraphImageComponentAges
...ParagraphQuote
...ParagraphImageWithTitleAndDescriptionAges
...ParagraphQuote2
...ParagraphImageText
}
}
}
}
}
}
fragment ParagraphTitleAndDescription on paragraph__title_and_description {
id
field_title
text: field_description {
processed
}
}
fragment ParagraphImageComponentAges on paragraph__image_component {
id
image: field_image {
alt
}
relationships {
image: field_image {
id
uri {
    value
    url
}
}
}
}
fragment ParagraphHTMLText on paragraph__html_text {
id
htmlText: field_text {
processed
}
}
fragment ParagraphInset on paragraph__inset {
id
inset: field_inset_text
}
fragment ParagraphQuote on paragraph__quote {
id
quote: field_quotation
}
fragment ParagraphQuote2 on paragraph__quote_variation_2 {
id
pre: field_pre_quote
quote: field_quotation
post: field_post_quote
}
fragment ParagraphImageWithTitleAndDescriptionAges on paragraph__image_with_title_and_description {
id
title: field_title
description: field_content {
processed
}
image: field_image {
alt
}
alignment: field_alignment
relationships {
image: field_image {
id
uri {
    value
    url
}
}
}
}
fragment ParagraphImageText on paragraph__image_text {
id
text: field_text_demo {
processed
}
image: field_image_demo {
alt
}
relationships {
image: field_image_demo {
id
uri {
    value
    url
}
}
}
}
fragment ParagraphTabComponentAges on paragraph__tab_components {
id
title: field_title
relationships {
components: field_components {
__typename
...ParagraphInset
...ParagraphHTMLText
...ParagraphImageComponentAges
}
}
}
`
export default AboutHindujaAges